import * as React from "react";
import Layout from "../components/layout";
import { styles } from "../shared-styles";

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact">
      <section style={styles.ContentSection}>
        <p>Dacă sunteți interesați să beneficiați de serviciile noastre, ne puteți contacta telefonic sau prin e-mail,
          în vederea stabilirii unei întâlniri. <span style={{ fontWeight: "700" }}>În funcție de nevoile clientului, prin excepție, putem stabili întâlniri și în afara orelor de program.</span>
        </p>
      </section>
    </Layout>
  );
};

export default ContactPage;
